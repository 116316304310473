<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    ACTIVE STUDENTS ({{studentCount}})
                </v-card-title>
                <v-card-subtitle>
                    Manage Students - Add/Update
                </v-card-subtitle>
                <v-card-actions>
                    <v-btn text color="info" @click="reset">Add New Student</v-btn> &nbsp; | &nbsp;
                    <promote-demote-students :classes="classes" :authToken="authToken" :school="school" @promoted="getRecords">Promote Students</promote-demote-students>
                </v-card-actions>
                <v-card-text>
                    <v-row>
                        <!-- Create /Update Form -->
                        <v-col cols="12" v-if="newform">
                            <v-form v-model="valid" ref="form">
                                <v-container>
                                    <v-row>                                        
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.surname"
                                                label="Surname"
                                                :rules="[v => !!v || 'Surname is required']"
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                outlined
                                                v-model="form.firstname"
                                                label="First Name"
                                                :rules="[v => !!v || 'First Name is required']"
                                                required
                                            ></v-text-field>
                                            <v-text-field
                                                outlined
                                                v-model="form.middlename"
                                                label="Middle Name (optional)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="form.gender"
                                                outlined
                                                :items="gender"
                                                item-text="name"
                                                item-value="id"
                                                label="Gender (optional)"
                                            ></v-select>
                                            <v-select
                                                v-model="form.clasx_id"
                                                v-if="!editBtn"
                                                outlined
                                                :items="classes"
                                                item-text="class"
                                                item-value="class_id"
                                                label="Current Class"
                                                :rules="[v => !!v || 'Class is required']"
                                                required
                                            ></v-select>
                                            <v-select
                                                v-model="form.status"
                                                v-else
                                                outlined
                                                :items="['active','inactive']"
                                                label="Select Inactive if student left school"
                                            ></v-select>
                                            <v-select
                                                v-model="form.year_admitted"
                                                outlined
                                                :items="years"
                                                :rules="[v => !!v || 'Year admitted is required']"
                                                required
                                                label="Year Admitted"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                        <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="form.dob"
                                                        outlined
                                                        label="Date of Birth (optional)"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                v-model="form.dob"
                                                @input="menu2 = false"
                                                ></v-date-picker>
                                            </v-menu>
                                            <v-text-field
                                                outlined
                                                v-model="form.fileno"
                                                label="File Number (optional)"
                                                min="0"
                                                type="number"
                                            ></v-text-field>
                                            <v-text-field
                                                outlined
                                                v-model="form.parent_name"
                                                label="Parent Name (optional)"
                                            ></v-text-field>
                                        </v-col>                                         
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                        <img
                                        v-if="editBtn"
                                        :src="form.passport_view"
                                        height="30"/>
                                        <v-file-input
                                            v-if="school.plan != 'Free'"
                                            filled
                                            :rules="[value => !value || value.size < 500000 || 'Passport size should be less than 500kb!']"
                                            accept="image/png, image/jpeg, image/jpg"
                                            placeholder="Student Passport (optional)"
                                            prepend-icon="mdi-camera"
                                            label="Student Passport (optional)"
                                            @change="passport = true"
                                        ></v-file-input>
                                        <v-chip v-if="editBtn">Current Class: -- <strong> {{form.clasx_id}}</strong></v-chip>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                        <v-select
                                                v-model="form.class_change"
                                                v-if="editBtn"
                                                outlined
                                                :items="['yes', 'no']"
                                                label="Move to Another Class? (Optional)"
                                        ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="6"
                                        >
                                        <v-select
                                                v-model="form.clasx_id"
                                                v-if="editBtn"
                                                outlined
                                                :items="classes"
                                                item-text="class"
                                                item-value="class_id"
                                                label="Select the Class to move"
                                        ></v-select>
                                        </v-col>
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="postRecord" v-if="!editBtn"
                                        >
                                            Create
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="updateRecord" v-else
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn tile text color="secondary" @click="newform = false">
                                            <v-icon left>mdi-back</v-icon>
                                            Go Back
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                        <!-- Records -->
                        <v-col cols="12" v-else>
                            <v-alert
                                type="info"
                                dense
                                border="left"
                                text
                                color="orange"
                                >
                                <marquee>
                                    <span v-for="(info,i) in infos" :key="i" class="black--text mr-5">
                                    {{info.content}} | &nbsp;
                                    </span>
                                </marquee>
                            </v-alert>
                            <info-loader-2 v-if="fetchingContent"/>
                            <v-row class="mb-1 mt-2" v-else>
                                <v-col cols="12" v-for="(c, i) in classes" :key="i">
                                    <h3 class="title mb-3 purple--text">{{c.school_arm.type}} \ {{c.class}} <v-chip color="yellow">{{studentClassCount(c.class)}} Students</v-chip></h3>
                                    <v-divider></v-divider>
              
                                    <v-row class="mt-1">
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="4" v-for="(record, i) in records.filter((item)=>{return item.current_class == c.class})" :key="i">
                                            <v-card elevation="2" color="#F5F5F5" v-if="record.status == 'active'">
                                                <v-card-title>                                     
                                                    <img
                                                    :src="record.passport"
                                                    height="30"/>&nbsp;        
                                                    <span >{{record.surname}} {{record.firstname}}</span>&nbsp;
                                                    <v-btn @click="editRecord(record)" fab light x-small
                                                    ><v-icon dark> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-subtitle>
                                                    <v-chip small color="#dde8ff" v-if="record.gender == 0">{{record.gender_letter}}</v-chip><v-chip small color="#FFCDD2" v-else-if="record.gender == 1">{{record.gender_letter}}</v-chip><v-chip small color="#FF000" v-else>No Gender</v-chip> /
                                                    {{record.student_regno}}
                                                </v-card-subtitle>
                                            </v-card>
                                            <v-card elevation="1" color="white" v-else>
                                                <v-card-title>                                     
                                                    <span class="grey--text caption"><del>{{record.surname}} {{record.firstname}}</del></span>&nbsp;
                                                    <v-btn @click="editRecord(record)" fab light x-small
                                                    ><v-icon dark> mdi-pencil </v-icon>
                                                    </v-btn>
                                                </v-card-title>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import HeaderVue from '../../components/Header.vue'
import PromoteDemoteStudents from '../../components/PromoteDemoteStudents.vue';
import InfoLoader2 from '../../components/InfoLoader2.vue';

  export default {
    name: 'Students',
    components: {
        HeaderVue,
        PromoteDemoteStudents,
        InfoLoader2
    },
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.getClasses()
       this.getRecords()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        newform: false,
        editBtn: false,
        form:{},
        passport: false,
        studentCount: null,
        records:[],
        years:['2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021','2022', '2023', '2024'],
        gender:[
            {id:'0', name:'Male'},
            {id:'1', name:'Female'},
        ],
        classes:[],
        infos:[
            
            {content: 'You can now hide students who have left your school by clicking the pencil icon and select status -- inactive'},
            {content: 'When you switch from OLD session to NEW Session on our software, your Student records are hidden because they are no longer in the old class. To display them in their new class, Click the Promote Students Button above and promote them from old class to new class'}
        ],
        valid: true,
        menu2:false,
        fetchingContent:true
    }),
    computed: {
        //
    },
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        reset () {
            this.newform = true
            this.editBtn = false
            this.form = {}
        },
        studentClassCount(className) {
            let counter = 0;
            for (const obj of this.records) {
                if (obj.current_class === className && obj.status === 'active') counter++;
            }
            return counter;
        },
        getClasses () {
            axios.get(process.env.VUE_APP_BASEURL+'/classes?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.classes =  response.data.data
                 if (this.classes.length == 0) {
                    this.$toast.info('You will need to set classes first!', {
                            timeout: 5000
                        }); 
                    this.$router.push('classes')
                }           
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
            });
        },
        getRecords () {
            axios.get(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.records =  response.data.data
                this.studentCount = response.data.count           
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
                this.fetchingContent = false;
            });
        },
        uploadImage (recordId) {
            if (this.passport){
                let formdata = new FormData()
                formdata.append('passport', document.querySelector('input[type=file]').files[0])
                formdata.append('studentId', recordId)
                let loader = this.$loading.show()
                axios.post(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id,formdata,
                {
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken,
                        'Content-Type' : 'multipart/form-data'
                    }
                })
                .then(() => {                                
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    loader.hide()
                });
            }
            this.passport = false
            this.getRecords()
            this.editBtn = false             
            this.newform = false 
        },
        postRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/students?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                });
                this.uploadImage(response.data.data.student_id)             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        editRecord (record){
            this.newform = true
            this.form.id = record.student_id
            this.form.surname = record.surname
            this.form.firstname = record.firstname
            this.form.middlename = record.middlename
            this.form.gender = record.gender
            this.form.year_admitted = record.year_admitted
            this.form.dob = record.dob
            this.form.parent_name = record.parent_name
            this.form.fileno = record.fileno
            this.form.status = record.status
            this.form.passport_view = record.passport
            this.form.clasx_id = record.current_class
            this.editBtn = true
        },
        updateRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/students/'+this.form.id+'?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.uploadImage(this.form.id)            
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        deleteRecord (record) {
            if(confirm("Do you really want to delete?")){
                let loader = this.$loading.show()
                axios.delete(process.env.VUE_APP_BASEURL+'/students/'+record.student_id+'?school_id='+this.school.school_id+'&confirm=DELETE',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then(response => {
                     this.$toast.success(response.data.message, {
                        timeout: 5000
                    }); 
                    this.getRecords()
                })
                .catch(error => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            }
        }
    },
  }
</script>
